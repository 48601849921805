// extracted by mini-css-extract-plugin
export var osdGrid = "osd-module--osdGrid--3SN_j";
export var osdViewerWrapper = "osd-module--osdViewerWrapper--wndKn";
export var osdViewerInner = "osd-module--osdViewerInner--nDZks";
export var hotspotPopupsContainer = "osd-module--hotspotPopupsContainer--1jmzu";
export var hotspotDrawer = "osd-module--hotspotDrawer--3HJua";
export var touchwallControlsContainer = "osd-module--touchwallControlsContainer--3_0Ij";
export var touchwallControls = "osd-module--touchwallControls--2t8Ds";
export var osdNavWrapper = "osd-module--osdNavWrapper--BGU-b";
export var filtersTouchwall = "osd-module--filtersTouchwall--188Sw";
export var filtersWeb = "osd-module--filtersWeb--3grFv";
export var sidebar = "osd-module--sidebar--3TD-M";
export var touchscreenGrid = "osd-module--touchscreenGrid--HzRnm";
export var hotspotPopupOverlay = "osd-module--hotspotPopupOverlay--7G3_0";