import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Filters from '../components/Filters';
import HotspotCardList from '../components/HotspotCardList';
import HotspotDrawer from '../components/HotspotDrawer';
import HotspotPopup from '../components/HotspotPopup';
import OSDNavigator from '../components/OSDNavigator';
import OSDViewer from '../components/OSDViewer';
import Layout from '../Layout';
import { clickedScreenState, newsletterOpenState } from '../recoil/Atoms';
import {
  filtersTouchwall,
  filtersWeb,
  hotspotDrawer,
  hotspotPopupOverlay,
  hotspotPopupsContainer,
  osdGrid,
  osdNavWrapper,
  osdViewerInner,
  osdViewerWrapper,
  sidebar,
  touchwallControls,
  touchwallControlsContainer,
} from './osd.module.scss';

const Osd = ({ data, location }) => {
  // console.log(data);
  const [viewer, setViewer] = useState(null); // TODO is this going to be used in components other than OSDViewer?
  const [activeHotspot, setActiveHotspot] = useState('');
  const clickedScreen = useRecoilValue(clickedScreenState);
  const newsletterOpen = useRecoilValue(newsletterOpenState);
  const hotspots = data.allStrapiHotspots.nodes;

  /* Filter buttons set the active category.
  activeCategory values:
  No categories selected: 'NONE'
  Mural: 'EVENT', 'PERSON', 'PLACE', 'ALL_MURAL';
  Map: 'ORGANIZATION', 'SITE', 'WAR', 'ALL_MAP';
  */
  const [activeCategory, setActiveCategory] = useState('NONE');
  // The default activeCategory value needs to be set to ALL_MURAL or ALL_MAP depending on which page loads
  useEffect(() => {
    switch (true) {
      case data.strapiOsds.osd_id === 'mural':
        setActiveCategory('ALL_MURAL');
        break;
      case data.strapiOsds.osd_id === 'map':
        setActiveCategory('ALL_MAP');
        break;
      default:
        return console.error(
          'osd.js did not find the correct data. No active category set.'
        );
    }
    return () => {
      setActiveCategory('NONE');
    };
  }, [data]);

  // Darken the background when a popup is open
  const popupsContainerRef = useRef();
  const openHotspotTlRef = useRef();
  const closeHotspotTlRef = useRef();
  // TODO I think there's a new approach to selecting elements, look at gsap.context() (and .revert() for cleanup) https://greensock.com/docs/v3/GSAP/gsap.context()
  const selectEl = gsap.utils.selector(popupsContainerRef);
  // After activeHotspot has been reset to '', gsap still needs to know which hotspot to close
  const [lastActiveHotspot, setLastActiveHotspot] = useState('');
  // Hide all the hotspots when the viewer initiates
  useEffect(() => {
    viewer && gsap.set(selectEl('.hotspotPopup'), { autoAlpha: 0 });
    // eslint-disable-next-line
  }, [viewer]);

  // Open and close hotspots
  // IMPORTANT: this logic only works because the visitor has to click the close button (setting activeHotpot to '') before opening another hotspot.
  // This means Hotspots and HotspotDrawer items must be disabled when a HotspotPopup is open.
  // If those things aren't disabled, and clicking on a new hotspot must first close the current hotspot, the logic (and GSAP) gets confusing... so lets cross that bridge only if we have to.
  useEffect(() => {
    if (viewer) {
      openHotspotTlRef.current = gsap.timeline({ paused: true });
      closeHotspotTlRef.current = gsap.timeline({ paused: true });
      // TODO I think this would be cleaner with .reverse(), but I'm not sure how it would work with activeHotspot vs lastActiveHotspot.
      // Show the popup
      if (activeHotspot !== '') {
        setLastActiveHotspot(activeHotspot);
        openHotspotTlRef.current
          .addLabel('start')
          .to(
            popupsContainerRef.current,
            {
              background: 'rgba(0, 0, 0, 0.3)',
              duration: 0.3,
            },
            'start'
          )
          .to(
            selectEl(`#popup-${activeHotspot}`),
            { autoAlpha: 1, duration: 0.3 },
            'start+=0.5'
          )
          // This works for moving focus to the popup... but things get complicated fast
          // 1. The UX needs some thought for the web layout.
          // 2. OpenSeadragon doesn't seem to handle keyboard nav well to begin with, tiles don't load.
          // .call(
          //   () => {
          //     selectEl(`#popup-${activeHotspot}`)[0].focus();
          //   },
          //   null,
          //   'start+=0.5'
          // )
          .to(
            selectEl(`#clickOverlay`),
            { pointerEvents: 'auto', duration: 0.3 },
            'start+=1'
          );
        openHotspotTlRef.current.play();
      } else {
        // This condition prevents GSAP from showing a "Target not found" warning when the page loads
        if (lastActiveHotspot !== '') {
          // Hide the popup
          closeHotspotTlRef.current
            .addLabel('start')
            .to(
              selectEl(`#popup-${lastActiveHotspot}`),
              { autoAlpha: 0, duration: 0.3 },
              'start'
            )
            .to(
              popupsContainerRef.current,
              {
                background: 'rgba(0, 0, 0, 0)',
                duration: 0.3,
              },
              'start+=0.5'
            )
            // For keyboard navigation we would want a .call() here to
            // return focus to overlay marker/image, I think.
            .to(
              selectEl(`#clickOverlay`),
              { pointerEvents: 'none', duration: 0.3 },
              'start+=0.5'
            );
          closeHotspotTlRef.current.play();
        }
      }
    }
    return () => {
      // Add cleanup with .context() and .revert()?
    };
    // I don't think we need lastActiveHotspot as a dependency, seems to work fine without it
    // eslint-disable-next-line
  }, [viewer, activeHotspot]);

  // Close the hotspot if the Newsletter popup is open
  useEffect(() => {
    newsletterOpen && setActiveHotspot('');
  }, [newsletterOpen]);

  return (
    <Layout location={location}>
      <h1 className={`srOnly`}>{data.strapiOsds.name}</h1>
      <div className={`${osdGrid}`}>
        <div className={`${osdViewerWrapper}`}>
          <div className={`${osdViewerInner}`}>
            <OSDViewer
              osdId={data.strapiOsds.osd_id}
              pageId={data.strapiOsds.osd_id}
              xmlData={data.strapiOsds.xml_data}
              viewer={viewer}
              setViewer={setViewer}
              hotspots={hotspots}
              activeHotspot={activeHotspot}
              setActiveHotspot={setActiveHotspot}
              activeCategory={activeCategory}
              // setClickedScreen={setClickedScreen}
              isHome={false}
            />
          </div>
          {/* Touchwall Popups */}
          <div
            className={`${hotspotPopupsContainer}`}
            style={{
              justifyItems: {
                leftScreen: 'start',
                centerScreen: 'center',
                rightScreen: 'end',
              }[clickedScreen],
              pointerEvents: `${activeHotspot === '' ? 'none' : 'auto'}`,
            }}
            ref={popupsContainerRef}
          >
            <div
              className={`${hotspotPopupOverlay}`}
              tabIndex={0}
              role="button"
              onClick={() => setActiveHotspot('')}
              id={`clickOverlay`}
              onKeyDown={(e) =>
                (e.key === 'Enter' || e.key === ' ') && setActiveHotspot('')
              }
            />
            {/* Loading all hotspot popups, not just the active hotspot, to make any animations easier. */}
            {hotspots.map((hotspot, index) => (
              <HotspotPopup
                key={hotspot.hotspot_id}
                hotspot={hotspot}
                // activeHotspot={activeHotspot} // This is handled with GSAP above
                setActiveHotspot={setActiveHotspot}
                clickedScreen={clickedScreen}
                index={index}
              />
            ))}
          </div>
          {/* Touchwall Drawer */}
          <div className={`${hotspotDrawer}`}>
            <HotspotDrawer
              activeCategory={activeCategory}
              hotspots={hotspots}
              activeHotspot={activeHotspot}
              setActiveHotspot={setActiveHotspot}
              clickedScreen={clickedScreen}
            />
          </div>
        </div>
        {/* Touchwall navigator and filters */}
        <div className={`${touchwallControlsContainer}`}>
          <div
            className={`${touchwallControls} ${
              {
                leftScreen: 'navLeft',
                centerScreen: 'navCenter',
                rightScreen: 'navRight',
              }[clickedScreen]
            }`}
          >
            <div className={`${osdNavWrapper}`}>
              <OSDNavigator />
            </div>
            <div className={`${filtersTouchwall}`}>
              <Filters
                pageName={data.strapiOsds.name}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            </div>
          </div>
        </div>
        {/* Web filters and card list */}
        <div className={`${sidebar}`}>
          <details className={`${filtersWeb}`}>
            <summary className={`buttonTextAction textCenter`}>Filter</summary>
            <div className={`mt12`}>
              <Filters
                pageName={data.strapiOsds.name}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            </div>
          </details>
          <HotspotCardList
            hotspots={hotspots}
            activeHotspot={activeHotspot}
            setActiveHotspot={setActiveHotspot}
            activeCategory={activeCategory}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    strapiOsds(osd_id: { eq: $slug }) {
      name
      osd_id
      xml_data {
        Image {
          xmlns
          Format
          Overlap
          TileSize
          Size {
            Height
            Width
          }
          Url
        }
      }
    }
    allStrapiHotspots(
      sort: { fields: [sort_order, title], order: [ASC, ASC] }
      filter: { osd: { osd_id: { eq: $slug } } }
    ) {
      nodes {
        body
        category
        credit
        hotspot_id
        title
        hotspot_image {
          height
          width
          ext
          localFile {
            svgData
          }
        }
        media {
          alternativeText
          ext
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 480)
            }
          }
        }
        mural_insert {
          alternativeText
          ext
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 480)
            }
          }
        }
        osd {
          osd_id
        }
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                layout: CONSTRAINED
                aspectRatio: 1
                width: 160
              )
            }
          }
        }
        x_position
        y_position
      }
    }
  }
`;

export default Osd;
